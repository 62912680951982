<template>
  <b-container class="h-100">
    <b-row align-h="center" class="h-100">
      <b-col lg="6" md="8" sm="12" align-v="center" align-self="center" class="mb-auto mt-auto">
        <div class="cb-title-container" align-h="center">
          <div class="cb-title">
            <h1><b>ᴄᴀᴛᴀʟᴏɢ</b></h1>
            <small class="align-self-end">ʙʀᴏᴡsᴇʀ</small>
          </div>
        </div>
        <b-card no-body>
          <b-tabs card class="mt-0">
            <b-tab active>
              <template slot="title">
                <small>LDAP</small>
              </template>
              <b-form class="login" @submit.prevent="handleLogin">
                <b-form-group id="ldap-username-group">
                  <label label-for="ldap-username"> Username<span class="text-danger">*</span> </label>
                  <b-form-input
                    id="ldap-username"
                    v-model="user.username"
                    type="text"
                    name="username"
                    placeholder=""
                    required
                    :state="message ? false : null"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="ldap-password-group">
                  <label label-for="ldap-password"> Password<span class="text-danger">*</span> </label>
                  <b-form-input
                    id="ldap-password"
                    v-model="user.password"
                    type="password"
                    name="password"
                    placeholder=""
                    autocomplete="on"
                    :state="message ? false : null"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback id="ldap-password-feedback">
                    {{ message }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button-group>
                  <b-dropdown right variant="outline-dark" split-button-type="button">
                    <template slot="button-content" type="button">
                      <b-badge variant="dark">{{ environment }}</b-badge>
                    </template>
                    <b-dropdown-item :active="getCurrentEnv() == backend.env" @click="environment = backend.env"  v-for="backend in this.backends" :key="backend.env"> 
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{{ backend.description }} </span>
                        <small><b-badge variant="dark">{{ backend.env }}</b-badge></small>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button type="submit" variant="dark">
                    <span>{{ ' Login ' }}</span>
                  </b-button>
                </b-button-group>
              </b-form>
            </b-tab>
            <b-tab>
              <template slot="title">
                <small>PLATFORM</small>
              </template>
              <b-form class="login" @submit.prevent="platformAuth" @submit="platformAuth">
                <b-form-group
                  :id="'platform-email-group'"
                  :description="
                    'Platform ' + (environment == 'catalog production' ? 'production' : 'development or staging') + ' identifier.'
                  "
                >
                  <label label-for="platform-email"> Email<span class="text-danger">*</span> </label>
                  <b-form-input
                    :id="'platform-email'"
                    v-model="user.email"
                    type="email"
                    name="email"
                    placeholder=""
                    required
                    :state="message_platform ? false : null"
                  ></b-form-input>
                </b-form-group>
                <b-form-group :id="'platform-password-group'">
                  <label label-for="iplatform-password"> Password<span class="text-danger">*</span> </label>
                  <b-form-input
                    :id="'platform-password'"
                    v-model="user.password"
                    type="password"
                    name="password"
                    placeholder=""
                    autocomplete="on"
                    required
                    :state="message_platform ? false : null"
                  ></b-form-input>
                  <b-form-invalid-feedback id="platform-password-feedback">
                    {{ message_platform }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button-group>
                  <b-dropdown right variant="outline-dark" split-button-type="submit">
                    <template slot="button-content" type="submit">
                      <b-badge variant="dark">{{ environment }}</b-badge>
                    </template>
                    <b-dropdown-item :active="getCurrentEnv() == backend.env" @click="environment = backend.env"  v-for="backend in this.backends.filter(b => b.platformAuth)" :key="backend.env"> 
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{{ backend.description }} </span>
                        <small><b-badge variant="dark">{{ backend.env }}</b-badge></small>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button type="submit" variant="dark">
                    <span>{{ ' Login ' }}</span>
                  </b-button>
                </b-button-group>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { LDAPUser, PlatformUser, User } from '../models/user'
import { backends, platformAuthEndpoints } from '../util'
import common from './common'

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      message: '',
      message_platform: '',
      user: {
        username: '',
        email: '',
        password: ''
      },
      loading: false,
      environment: '',
      backends: backends
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    ...common.methods,
    handleLogin() {
      this.loading = true
      var ldap_user = new LDAPUser(this.user.username, this.user.password)
      this.saveUserAndReroute(ldap_user)
    },
    getSelectedEnv() {
      return this.backends.find(b => {
        return b.env === this.environment
      })
    },
    platformAuth() {
      this.loading = true
      const env = this.getSelectedEnv()
      if (!env.platformAuth) {
        return
      }

      const url_login = new URL(platformAuthEndpoints[env.platformAuth])
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(this.user)
      }

      fetch(url_login, options).then(async (resp) => {
        if (!resp.ok) {
          this.message_platform = 'Failed to authenticate with provided credentials.'
          this.user.password = ''

          return Promise.reject(resp)
        }
        const data = await resp.json()
        const token = data['token']
        const p_user = new PlatformUser(token)

        this.saveUserAndReroute(p_user)
      }, this.loginError)
    },
    saveUserAndReroute(user) {
      let path = this.$route.query.redirect || '/'
      const backend = this.getSelectedEnv()
      if (backend.env != "catalog production") {
        const endpoint = encodeURIComponent(backend.endpoint)
        if (path.includes("?")) {
          path += "&endpoint=" + endpoint
        } else {
          path += "?endpoint=" + endpoint
        }
      }
      this.$store.dispatch('auth/login', {
        user: user,
        endpoint: backend.endpoint
      }).then(() => {
        this.$router.push({ path })
      }, this.loginError)
    },
    loginError(error) {
      this.loading = false
      this.user.password = ''
      this.message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    }
  },
  mounted() {
    this.environment = this.getCurrentEnv()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="./base.css"></style>

<style>
body {
  height: 100vh;
  margin-bottom: 0;
}
</style>
<style scoped lang="css">
.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
}

.leaflet-container {
  background-color: #262626;
}

#locator-map {
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
}

#map-container {
  height: 500px;
}

#map {
  height: 100%;
  width: 100%;
}

#header_logo {
  max-width: 100px;
}

.table-responsive.assets {
  padding: 15px;
}

.multiselect {
  margin-top: 5px;
}
.title-env {
  width: 100%;
  display: inline-block;
}
.title-env * {
  display: inline;
}

.cb-title > h1 {
  font-size: 5rem !important;
}
.cb-title > small {
  font-size: 2rem !important;
  position: relative;
  top: -1.5rem;
}
.cb-title > * {
  display: flex;
  justify-content: flex-end;
}
.cb-title {
  max-inline-size: min-content;
}
.cb-title-container {
  text-align: -moz-center;
  text-align: -webkit-center;
}
</style>
